@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,700&display=swap');@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,700&display=swap');

html, body, #app, #app>div, root {
    height: 100%;
    width: 100%;
}

html,body, #st-full-pg {
    margin: 0;
}

.roboto {
    font-family: Roboto, serif;
}

.logo {
    font-family: 'Bebas Neue', cursive;
}

.i-bg {
    background-color: #ffa69e;
}

.ii-bg {
    background-color: #B2CEDE;
}

.iii-bg {
    background-color: #8CDFD6;
}

.iiii-bg {
    background-color: #6dc0d5;
}

.iiiii-bg {
    background-color: #5a716a;
}

.i-text {
    color: #ffa69e;
}

.ii-text {
    color: #B2CEDE;
}

.iii-text {
    color: #8CDFD6;
}

.iiii-text {
    color: #6dc0d5;
}

.iiiii-text {
    color: #5a716a;
}

.dialog {
    display: flex;
    justify-items: center;
    justify-content: center;
    font-family: "Roboto", serif;
}

.code-area {
    font-family: "JetBrains Mono", "serif"
}

code {
    font-family: "JetBrains Mono", "serif";
}

.full-height {
    height: 100%;
    width: 100%;
}

.markdown code {
    background-color: rgba(125, 114, 114, 0.34);
    border-radius: 2px;
    padding: 0 4px;
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.markdown h1 {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}

.markdown h2 {
    font-size: 30px;
    font-weight: bold;
}

.markdown h3 {
    font-size: 27px;
    font-weight: bold;
}

.markdown h4 {
    font-size: 25px;
    font-weight: bold;
}

.markdown p {
    font-family: "Roboto", serif;
    font-size: 20px;
}

.markdown ol {
    padding: 0 2em;
    list-style-type: decimal;
    font-family: "Roboto", serif;
    font-size: 20px;
}

.markdown ul {
    padding: 0 2em;
    font-family: "Roboto", serif;
    font-size: 20px;
    list-style-type: circle
}

.markdown blockquote {
    margin-left: 2em;
    font-family: "Roboto", serif;
    font-size: 20px;
    font-style: italic;
    background-color: white;
    border-radius: 1em;
    padding: .5em 2em;
}